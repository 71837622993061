import enLocale from '@/addons/locale/lang/en'
import Config from '../../config/Config'

export default {
  ...enLocale,
  label: 'English',
  labelMobile: 'EN',
  serverError: 'Unexpected server error. Try again.',
  email: 'Email',
  password: 'Password',
  login: 'Login',
  edit: 'Edit',
  back: 'Back',
  cancel: 'cancel',
  users: 'Users',
  name: 'Name',
  roles: 'Roles',
  enabled: 'Enabled',
  ROLE_ADMIN: 'Admin',
  ROLE_OPERATOR: 'Operator',
  ROLE_SUPER_ADMIN: 'Superadmin',
  editUser: 'Edit user',
  newUser: 'New user',
  externalAuthentication: 'External authentication',
  confirm: 'Confirm',
  deleteAlert: 'Are you sure you want to delete this item?',
  description: 'Description',
  code: 'Code',
  value: 'Value',
  newValue: 'New value',
  save: 'Save',
  lovValues: 'LOV values',
  editLov: 'Edit LOV',
  lovs: 'LOVs',
  newLov: 'New LOV',
  delete: 'Delete',
  errors: {
    required: 'This field is required',
    email: 'Please input correct email address',
    url: 'Please input correct URL',
    phone: 'Please input a correct phone number'
  },
  networkElements: 'Network elements',
  editNetworkElement: 'Edit network element',
  attributes: 'Attributes',
  newAttribute: 'New attribute',
  type: 'Type',
  mandatory: 'Mandatory',
  lov: 'LOV',
  integer: 'Integer',
  INTEGER: 'Integer',
  addChild: 'Add node',
  editChild: 'Edit node',
  newParent: 'New generator',
  newFirstLevelNode: 'New first level node',
  newChild: 'New node',
  network: 'Network',
  networkHorizontal: 'Horizontal network',
  networkVertical: 'Vertical network',
  fileImported: 'File imported correctly',
  import: 'Import',
  rows: 'Rows',
  created: 'Created',
  status: 'Status',
  maneuvers: 'Maneuvers',
  maneuver: 'Maneuver',
  networkElement: 'Network element',
  technicalLosses: 'Technical losses',
  technicalLossesPercent: 'Technical losses (%)',
  originSystemId: 'Origin system ID',
  latitude: 'latitude',
  longitude: 'longitude',
  draft: 'Draft',
  accept: 'Accept',
  importRow: 'Imported',
  processRow: 'Processed',
  validRow: 'Valid',
  createdAt: 'Created at',
  done: 'Done',
  filename: 'Filename',
  importDetail: 'Import detail',
  download: 'Download',
  logs: 'Logs',
  networkTree: 'Network tree',
  updatedAt: 'Update at',
  processLog: 'Process logs',
  approved: 'Approved',
  manual: 'Manual',
  accepted: 'Accepted',
  processed: 'Processed',
  changes: 'Changes',
  owner: 'Owner',
  validationsLog: 'Validations logs',
  networkUnit: 'Network unit',
  destination: 'Destination',
  deleted: 'Deleted',
  action: 'Action',
  pending: 'Pending',
  reject: 'Reject',
  approve: 'Approve',
  rejected: 'Rejected',
  string: 'String',
  select: 'Select',
  number: 'Number',
  move: 'move',
  selectDestinationNode: 'Select a destination node',
  moveNode: 'Move node',
  discard: 'Discard',
  networkTrees: 'Network trees',
  networkTreeLog: 'Network tree logs',
  modified: 'Modified',
  error: 'Error',
  from: 'From',
  to: 'To',
  dateRange: 'Date range',
  restore: 'Restore',
  actions: 'Actions',
  origin: 'Origin',
  version: 'Version',
  reconfiguration: 'Reconfiguration',
  networkHistory: 'Network history',
  moveNodeSameDestination: 'Select a different node destination',
  executionDate: 'Execution date',
  changePassword: 'Change password',
  confirmPassword: 'Confirm password',
  passwordDontMatch: "Passwords don't match",
  meterManagement: 'Meter management',
  serialNumber: 'Serial number',
  readingTypes: 'Reading types',
  model: 'model',
  brand: 'brand',
  position: 'Position',
  serviceType: 'Service type',
  serviceId: 'Service ID',
  input: 'Input',
  output: 'Output',
  prepaid: 'Prepaid',
  postpaid: 'Postpaid',
  active: 'Active',
  power: 'Power',
  reactive: 'Reactive',
  customer: 'Customer',
  customers: 'Customers',
  areasDefinition: 'Areas definition',
  newArea: 'New area',
  editArea: 'Edit area',
  selectedNodes: 'Selected nodes',
  selectNodesInfo: '-Select the nodes that defines this area in the tree.',
  periodsMaintenance: 'Periods maintenance',
  newPeriod: 'New period',
  editPeriod: 'Edit period',
  processing: 'Processing',
  closed: 'Closed',
  open: 'Open',
  initialDate: 'Initial date',
  lastDayReadingsCapture: 'Last day readings capture',
  closureDate: 'Closure date',
  meters: 'Meters',
  metersHorizontal: 'Horizontal meters',
  metersVertical: 'Vertical meters',
  purchases: 'Purchases',
  newPurchase: 'New purchase',
  editPurchase: 'Edit purchase',
  totalAmount: 'Total amount',
  totalEnergy: 'Total energy',
  reference: 'Reference',
  billOriginalReference: 'Bill original reference',
  measurementLoading: 'Measurement Management',
  measurementAnalysis: 'Measurement analysis',
  measurementValidation: 'Measurement validation',
  individualValidation: 'Measurement individual validation',
  consumption: 'consumption',
  date: 'date',
  reading: 'reading',
  period: 'Period',
  search: 'Search',
  readings: 'Readings',
  newReading: 'New reading',
  new: 'New',
  estimate: 'Estimate',
  runValidation: 'Run validation',
  editReading: 'Edit reading',
  analysis: 'Analysis',
  periodicity: 'Periodicity',
  readingPeriod: 'Reading period',
  real: 'Real',
  estimated: 'Estimated',
  system_estimated: 'System estimated',
  hourly: 'Hourly',
  daily: 'Daily',
  monthly: 'Monthly',
  consumptionType: 'Consumption type',
  consumptionOrigin: 'Consumption origin',
  readingOrigin: 'Reading origin',
  readingToBalance: 'Reading to balance',
  consumptionToBalance: 'Consumption to balance',
  lastReport: 'Last report',
  validateEmptyReadings: 'Validate empty readings',
  validateReadings: 'Validate readings',
  bulkValidations: 'Bulk validation',
  energyInput: 'Energy input',
  energyType: 'Energy type',
  balanceArea: 'Balance area',
  element: 'Element',
  average: 'Average',
  monthValue1: 'Month 1 value',
  monthValue2: 'Month 2 value',
  monthValue3: 'Month 3 value',
  monthValue4: 'Month 4 value',
  monthValue5: 'Month 5 value',
  monthValue6: 'Month 6 value',
  monthValue7: 'Month 7 value',
  monthValue8: 'Month 8 value',
  monthValue9: 'Month 9 value',
  monthValue10: 'Month 10 value',
  monthValue11: 'Month 11 value',
  monthValue12: 'Month 12 value',
  monthValue1Percent: 'M1 %',
  monthValue2Percent: 'M2 %',
  monthValue3Percent: 'M3 %',
  monthValue4Percent: 'M4 %',
  monthValue5Percent: 'M5 %',
  monthValue6Percent: 'M6 %',
  monthValue7Percent: 'M7 %',
  monthValue8Percent: 'M8 %',
  monthValue9Percent: 'M9 %',
  monthValue10Percent: 'M10 %',
  monthValue11Percent: 'M11 %',
  monthValue12Percent: 'M12 %',
  currentBalances: 'Current balances',
  includeLosses: 'Include losses',
  balanceDashboard: 'Balance dashboard',
  balanceDashboardHorizontal: 'Horizontal balance dashboard',
  balanceDashboardVertical: 'Vertical balance dashboard',
  lastMonthLosses: 'Last month losses',
  losses: 'Losses',
  lossesPercent: 'Losses %',
  lastMonthLossesPercent: 'Last month losses %',
  applyManeuvers: 'Apply maneuvers',
  maneuversApplied: 'Maneuvers applied',
  nodeSaved: 'The operation was successful',
  nodeCreated: 'Created',
  nodeMoved: 'Moved',
  nodeDeleted: 'Deleted',
  nodeBlocked: 'Blocked',
  colorsLegend: 'Color legend',
  noMeterAssociated: 'No meter associated',
  bulkValidationStarted:
    'The process has started. It will take some time to finish',
  meter: 'Meter',
  highEstimation: 'High estimation',
  highDeviation: 'High deviation',
  lowEstimation: 'Low estimation',
  potentialTurnover: 'Potential turnover',
  readingBounds: 'Reading bounds',
  consumptionBounds: 'Consumption Bounds',
  noReading: 'No reading',
  anomalies: 'Anomalies',
  noMeterSerialNumber: 'This node has not a meter associated',
  noMoreFiveComparison: "Don't select more than 5 nodes to compare",
  noHistorical: 'This node has not a historical associated',
  bulkEstimation: 'Bulk estimation',
  historical: 'Historical',
  balanceLog: 'Log',
  balanceLogTitle: 'Log Balance',
  balanceLogTitleValidation: 'Log Validation',
  readingsProcessed: 'Readings Processed',
  meterReadingsToProcess: 'Readings to process',
  noReadingsValidationLog: 'Validate empty readings log',
  validateReadingsLog: 'Validate readings log',
  bulkValidationLog: 'Bulk validation log',
  bulkEstimationLog: 'Bulk estimation log',
  totalJobs: 'Total jobs',
  executedJobs: 'Executed jobs',
  dailyBalance: 'Daily balance',
  periodCalculation: 'Calculate period',
  dailyBalanceStarted: 'Daily balance started',
  periodCalculationStarted: 'Calculation started',
  ERROR_MESSAGE_REPORT_NOT_FOUND: 'The report is not available',
  apiError: 'Ops there was an error',
  ERROR_MESSAGE_REQUEST_INVALID_USER_PASSWORD_COMBINATION:
    'Invalid user or password',
  ERROR_MESSAGE_THE_PROCESS_HAS_ALREADY_BEEN_STARTED:
    'The process has already been started',
  ERROR_MESSAGE_THE_PROCESS_DAILY_BALANCE_IS_RUNNING:
    'The process Daily Balance is running',
  ERROR_MESSAGE_METER_NOT_FOUND: 'Meter not found',
  ERROR_MESSAGE_ACTIVE_PERIOD_NOT_FOUND: 'Active period not found',
  ERROR_MESSAGE_INVALID_DATES_TO_CREATE_PERIOD:
    'Invalid dates to create a period',
  ERROR_MESSAGE_CLOSURE_DATE_HAS_TO_BE_GREATER_THAN_INITIAL_DATE:
    'Closure date has to be greater than initial date',
  ERROR_MESSAGE_INVALID_LAST_DAY_READING_CAPTURE:
    'Last day reading capture must be greater than initial date and lower than closure date',
  ERROR_MESSAGE_INVALID_DATE: 'Invalid date',
  ERROR_MESSAGE_BALANCE_AREA_NOT_FOUND: 'Area not found',
  ERROR_MESSAGE_INVALID_ORDER_BETWEEN_NODE_AND_PARENT:
    'Invalid order between node and parent',
  ERROR_MESSAGE_REPORT_TYPE_NOT_FOUND: 'Report type not found',
  ERROR_MESSAGE_INVALID_LAST_READING_DATE:
    'The date most be greater than the last reading date',
  order: 'order',
  process_tree: 'Process tree',
  done_pending: 'Done pending',
  ERROR_MESSAGE_PERIOD_NOT_FOUND: 'Period not found',
  activePeriodsNotFound: 'Active periods not found',
  REAL: 'Real',
  lastReadingDate: 'Last reading date',
  dateGreaterThanLastReadingDate:
    'The date most be greater than the last reading date',

  dateGreaterThanFromDate: 'The end date most be greater than the initial date',

  initialDateLessOrEqualsThanLastReadingDateCapture:
    'The initial date most be less or equals than the last reading date capture',
  initialDateLessOrEqualsThanClosureDate:
    'The initial date most be less or equals than the closure date',
  lastDayReadingsCaptureGreatherOrEqualsThanInitialDate:
    'The last reading date most be greather or equals than the initial date',
  lastDayReadingsCaptureLessOrEqualsThanClosureDate:
    'The last reading date most be less or equals than the closure date',
  initialDateGreatherOrEqualsThanInitialDate:
    'The closure date most be greather or equals than the initial date',
  initialDateGreatherOrEqualsThanLastReadingDateCapture:
    'The closure date most be greather or equals than the last reading date capture',

  readingImported: 'Imported reading',
  consumptionImported: 'Imported consumption',
  ERROR_MESSAGE_ENERGY_PURCHASE_NOT_FOUND: 'Energy purchase not found',
  ERROR_MESSAGE_THERE_IS_ALREADY_AN_ACTIVE_PERIOD:
    'There is already an active period',
  ERROR_MESSAGE_CAN_NOT_PERFORM_THIS_ACTION: 'Can not perform this action',
  ERROR_MESSAGE_NETWORK_TREE_IS_BLOCKED: 'Network tree is blocked',
  monetary: 'Monetary',
  selectPeriod: 'You must to select a period first',
  monetaryCalculation: 'Monetary calculation',
  nodeAlreadySelected: 'This node is already selected',
  ERROR_MESSAGE_NO_INPUT_TYPE_METER_IS_FOUND: 'No input type meter was found',
  selectNodesInfo2:
    '-Remember to select an input type meter as the first node.',
  selectNodesInfo3:
    '-When you select a node, the rest of the tree belonging to this node will be selected.',
  ERROR_MESSAGE_INVALID_NODE_TO_FORM_PART_OF_AN_AREA:
    'This node does not meet the requirements to be part of an area',
  unitCost: 'Unit cost',
  voltage: 'Voltage',
  region: 'Region',
  district: 'District',
  location: 'Location',
  tariff: 'Tariff',
  solved: 'Solved',
  ignored: 'Ignored',
  totalConsumption: 'Total consumption',
  strategicPlan: 'Strategic plan maintenance',
  initialLosses: 'Initial losses',
  initialLossesPercent: 'Initial losses (%)',
  lossesObjective: 'Losses Objective',
  lossesObjectivePercent: 'Losses Objective (%)',
  timePeriod: 'Time period(months)',
  interventionsPlanned: 'Interventions planned',
  estimatedCost: 'Estimated cost',
  estimatedCostDollar: `Estimated cost (${Config.currency})`,
  createPlan: 'Create plan',
  editPlan: 'Edit plan',
  createStategicPlan: 'Create strategic plan',
  area: 'Area',
  lessThan100: 'This field should be less than 100',
  lessOrEqualsThan100: 'This field should be less or equals than 100',
  greatherThan0: 'This field should be greather than 0',
  lessThanValue: 'This field should be less than ',
  lossesObjectiveLessThanInitialLosses:
    'The objetive losses must be less than initial losses',
  ERROR_MESSAGE_LOV_NOT_FOUND: 'LOV not found',
  strategicActivities: 'Strategic activities',
  newActivity: 'New activity',
  createActivity: 'Create activity',
  editActivity: 'Edit activity',
  refresh: 'Refresh',
  about: 'About',
  apiVersion: 'Api version',
  mongoVersion: 'Mongo version',
  mysqlVersion: 'Mysql version',
  phpVersion: 'Php version',
  webVersion: 'Web version',
  largePower: 'Large power',
  result: 'Result',
  realCost: `Real cost (${Config.currency})`,
  comment: 'Comment',
  addActionActivitie: 'Add actions / activities',
  negative: 'Negative',
  positive: 'Positive',
  NEGATIVE: 'Negative',
  POSITIVE: 'Positive',
  OPEN: 'Open',
  CLOSED: 'Closed',
  brandModels: 'Brands/Models',
  brands: 'Brands',
  createBrand: 'Create brand',
  editBrand: 'Edit brand',
  createModel: 'Create model',
  editModel: 'Edit model',
  models: 'Models',
  ERROR_MESSAGE_BRAND_NOT_FOUNT: 'Brand not found',
  underConstruction: 'This area is under construction',
  lossReductionTarget: 'Loss reduction target',
  lossReductionTargetPercent: 'Loss reduction target (%)',
  lossReductionTargetLessThanInitialLosses:
    'Loss reduction target must be less than initial losses',
  realCostRequired: 'Real cost should not be blank',
  activityHistory: 'Activity history',
  strategicPlanDetail: 'Strategic plan',
  detailPlan: 'Detail plan',
  ROLE_MANAGER: 'Manager',
  userDontHaveAcces: 'This user dos not have access to this section',
  networkElementDetail: 'Network element detail',
  elementType: 'Element type',
  initialFields: 'Initial fields',
  fieldsUpdated: 'Updated fields',
  ERROR_MESSAGE_STRATEGIC_PLAN_NOT_FOUND: 'Strategic plan not found',
  expectedRealCost: `Expected cost (${Config.currency})`,
  expectedExecutionDate: 'Expected execution date',
  otherComment: 'Other comment',
  forecastMeasurementLoading: 'Forecast Measurement Management',
  forecastMeasurementAnalysis: 'Forecast Measurement analysis',
  forecastMeasurementValidation: 'Forecast Measurement validation',
  consumptions: 'Consumptions',
  consumptionsHorizontal: 'Horizontal consumptions',
  consumptionsVertical: 'Vertical consumptions',
  consumptionForecast: 'Consumption forecast',
  editConsumption: 'Edit Consumption',
  addConsumption: 'Add Consumption',
  readingForecast: 'Reading forecast',
  readingForecastToBalance: 'Reading to balance forecast',
  consumptionForecastToBalance: 'Consumption to balance forecast',
  INSPECTIONS: 'Inspections',
  REPLACEMENT: 'Replacement',
  CONTROL_METER: 'Control meter',
  MAINTENANCE: 'Maintenance',
  includeForecast: 'Include Forecast',
  consumptionToDay: 'Consumption to day',
  consumptionForecastToDay: 'Consumption to day forecast',
  generatePeriods: 'Generate periods',
  year: 'Year',
  noDecimal: 'This field should no have decimals',
  greatherThan2000: 'This field should be greather than 2000',
  ERROR_MESSAGE_THIS_YEAR_ALREADY_EXISTS:
    'The periods of this year are already generated',
  startPeriod: 'Start period',
  onlyActivePlans: 'Only active plans',
  currentLoss: 'Current loss',
  currentLossPercent: 'Current loss(%)',
  lossReduction: 'Loss reduction',
  lossReductionPercent: 'Loss reduction(%)',
  profit: 'Profit',
  profitPercent: 'Profit(%)',
  benefit: 'Benefit',
  cost: 'Cost',
  monthlyLosses: 'Monthly losses',
  consumptionOriginForecast: 'Consumption origin forecast',
  detailStrategicPlan: 'Strategic Plan detail',
  totalLossReductionPercent: 'Total losses reduction(%)',
  totalBenefit: 'Total benefit',
  totalCost: 'Total cost',
  month: 'Month',
  lossesStart: 'Losses (start)',
  lossesEnd: 'Losses (end)',
  rank: 'Rank',
  filter: 'Filter',
  periodNotFound: 'No periods was found',
  firstTrimester: '1 Trimester',
  secondTrimester: '2 Trimester',
  thirdTrimester: '3 Trimester',
  fourTrimester: '4 Trimester',
  firstSemester: '1 Semester',
  secondSemester: '2 Semester',
  annual: 'Annual',
  downloadTable: 'Download table',
  custom: 'Custom',
  downloadTemplate: 'Download template',
  uploadTemplate: 'Upload template',
  templateUploaded: 'Template generated',
  ERROR_MESSAGE_FILE_NOT_FOUND: 'File not found',
  generateTemplate: 'Generate template',
  meterHistory: 'Meter history',
  replaceMeter: 'Replace meter',
  installationDate: 'Installation date',
  ERROR_MESSAGE_THIS_PLAN_IS_STARTED: 'This plan is already started',
  ERROR_MESSAGE_YOU_MUST_SPECIFY_SERIAL_NUMBER_OR_NETWORK_UNIT:
    'You must specify a serial number or a network unit',
  ignoreAnomalies: 'Ignore anomalies',
  estimateAnomalies: 'Estimate anomalies',
  ERROR_MESSAGE_ANOMALY_TYPE_NOT_FOUND: 'Anomaly type not found',
  measurementBulkValidation: 'Measurement bulk validation',
  resetOrigin: 'Reset origin',
  editConsumptionForMeter: 'Edit consumption for meter',
  addConsumptionForMeter: 'Add consumption for meter',
  section: 'Section',
  Energy: 'Energy',
  Water: 'Water',
  ERROR_MESSAGE_INVALID_MODULE:
    'This user dos not have access to the selected section',
  newParentWater: 'New water plant',
  address: 'Address',
  manufacturer: 'Manufacturer',
  volume: 'Volumen',
  volumeRest: 'Volumen restante',
  waterplants: 'Water plants',
  neighbors: 'Neighbors',
  hideDetails: 'Hide details',
  showDetails: 'Show details',
  details: 'Details',
  ERROR_MESSAGE_INVALID_NEIGHBOR: 'Invalid neighbor',
  ERROR_MESSAGE_NETWORK_UNIT_NOT_FOUND: 'Network unit not found',
  mostSelectLevel2: 'You can only select nodes with level 1 and 2',
  totalWater: 'Total water',
  canOnlySelectBrotherOrChildren:
    'You can only select a brother or a children node',
  waterInput: 'Water input',
  invalidData: 'Inconsistent data',
  ERROR_MESSAGE_INVALID_AREA: 'Invalid Area',
  ERROR_MESSAGE_THIS_NODE_CANNOT_BE_REMOVED: 'This node cannot be removed',
  ERROR_MESSAGE_INVALID_NETWORK_ELEMENT_TYPE: 'Invalid network element type',
  until: 'Until',
  ERROR_MESSAGE_INVALID_METER_READING: 'Invalid meter reading',
  ERROR_MESSAGE_INVALID_METER: 'Invalid meter',
  ERROR_MESSAGE_INVALID_ACTIVE_PERIOD_WITH_THIS_DATE:
    'Invalid active period with this date',
  ERROR_MESSAGE_INVALID_FILE: 'Invalid file',
  filterKeyword: 'Filter Keyword',
  onlyActivePeriod: 'Only active periods are allowed',
  balanceManagement: 'Balance management',
  balanceManagementHorizontal: 'Horizontal balance management',
  balanceManagementVertical: 'Vertical balance management',
  createBalance: 'Create balance',
  editBalance: 'Edit balance',
  editBalanceHorizontal: 'Edit horizontal balance',
  editBalanceVertical: 'Edit vertical balance',
  createBalanceHorizontal: 'Create horizontal balance',
  createBalanceVertical: 'Create vertical balance',
  entries: 'Entries',
  outs: 'Outs',
  clientType: 'Client type',
  generationType: 'Generation type',
  transportationDivision: 'Transportation division',
  voltageType: 'Voltage type',
  activeFilters: 'Active filters',
  ERROR_MESSAGE_NETWORK_NODE_NOT_FOUND: 'Network node not found',
  elementTypes: 'Element types',
  clientTypes: 'Client Types',
  generationTypes: 'Generation types',
  commercialOffice: 'ASC',
  activationDate: 'Activation date',
  deactivationDate: 'Deactivation date',
  getBalance: 'Get balance',
  balance: 'Balance',
  inputs: 'Inputs',
  outputs: 'Outputs',
  total: 'Total',
  totalReading: 'Total reading',
  inputTotalReading: 'Input total reading',
  inputTotalConsumption: 'Input total consumption',
  outputTotalReading: 'Output total reading',
  outputTotalConsumption: 'Output total consumption',
  balanceConsumption: 'Balance consumption',
  balanceReading: 'Balance reading',
  networkMap: 'Network map',
  balanceType: 'Balance type',
  borderType: 'Border type',
  billing: 'Billing',
  massiveImport: 'Massive import',
  ERROR_MESSAGE_THERE_IS_AN_IMPORT_FILE_READING_THAT_IS_ALREADY_BEING_IMPORTED: 'The is an import file reading that is already being imported'
}
