<template>
  <div>
    <ods-main-header
        headerLogoText="Onesait Balances"
        logoUrl="/"
        breadcrumbSeparator=">"
        actionsMenuIcon="overflow-menu"
        :showBreadcrumbs="true"
        :topBarBorder="false"
        :showUserMenu="true"
        :userMenuText="user.id ? user.userProfile && user.userProfile.name ? user.userProfile.name : user.email : 'User'"
        ref="header"
    >
      <template slot="notifications">
        <ods-select v-model="$i18n.locale">
          <ods-option
              v-for="lang in langs"
              :key="lang"
              :value="lang"
              :label="getLabel(lang)"></ods-option>
        </ods-select>
      </template>

      <template slot="module" v-if="userModule.module === 1">
          <img style="width:60%"  src="../assets/lightbulb-regular.svg" alt="">
      </template>

      <template slot="module" v-if="userModule.module !== 1">
          <img style="width:60%"  src="../assets/droplet-solid.svg" alt="">
      </template>

      <template slot="custom">
        <ods-button type="transparent" size = "small" @click="toggleDialog()"  style="margin-left:10px;display:inherit;float:left;margin-right:10px">
            <ods-icon name = "info"  style="margin-left:-10px;display:inherit;float:left;margin-right:10px"  size="20"/> {{ $t('about') }}
        </ods-button>
      </template>

      <template slot="user">
        <ul class="ods-actions-menu__ul ods-actions-menu--user__ul">
          <li>
            <router-link to="profile">
              <span data-name="OdsIcon" class="ods-icon-user"></span>Profile
            </router-link>
          </li>
          <li class="cursor-pointer">
            <a @click="logout()"><span data-name="OdsIcon" class="ods-icon-logout"></span>Log out</a>
          </li>
        </ul>
      </template>
    </ods-main-header>
    <div class="app-wrap">
      <ods-main-navigation v-if="!hasPermission && module"
          :collapsed="false"
          :navigation="energyOperatorNavigation"
          ref="energyOperatorNavigation"
      ></ods-main-navigation>
      <ods-main-navigation v-if="hasPermission && module"
          :collapsed="false"
          :navigation="energyNavigation"
          ref="energyNavigation"
      ></ods-main-navigation>
      <ods-main-navigation v-if="!hasPermission && !module"
          :collapsed="false"
          :navigation="waterOperatorNavigation"
          ref="waterOperatorNavigation"
      ></ods-main-navigation>
      <ods-main-navigation v-if="hasPermission && !module"
          :collapsed="false"
          :navigation="waterNavigation"
          ref="waterNavigation"
      ></ods-main-navigation>
      <ods-main>
        <ods-scrollbar wrapClass="ods-scrollbar--main-content">
          <router-view></router-view>
        </ods-scrollbar>
      </ods-main>
    </div>
      <AboutModal v-if="dialogVisible"
      v-show="dialogVisible"
      @close="closeModal" />
  </div>
</template>

<script>
import energyNavigationConfig from './energy-nav-config.json'
import energyNavigationConfigRoleOperator from './energy-nav-config-role-operator.json'
import waterNavigationConfig from './water-nav-config.json'
import waterNavigationConfigRoleOperator from './water-nav-config-role-operator.json'
import handlePromise from '@/utils/promise'
import UserService from '@/services/User'
import { mapState } from 'vuex'
import AboutModal from './AboutModal.vue'

export default {
  name: 'Private',
  components: {
    AboutModal
  },
  computed: {
    langs () {
      return Object.keys(this.$i18n.messages)
    },
    ...mapState({
      userData: state => state.user.data,
      userRole: state => state.userRoles.data,
      userModule: state => state.userModule.data
    })
  },
  mounted () {
    this.getUser()
    this.handlePermission()
  },
  data () {
    return {
      hasPermission: true,
      module: true,
      loading: false,
      energyNavigation: energyNavigationConfig,
      energyOperatorNavigation: energyNavigationConfigRoleOperator,
      waterNavigation: waterNavigationConfig,
      waterOperatorNavigation: waterNavigationConfigRoleOperator,
      wrapClass: '',
      user: {},
      dialogVisible: false
    }
  },

  methods: {
    toggleLoading () {
      this.loading = !this.loading
    },
    getLabel (lang) {
      return this.mobile ? this.$i18n.messages[lang].labelMobile : this.$i18n.messages[lang].label
    },
    logout () {
      this.$store.commit('user/logout')
      this.$store.commit('userModule/logout')
      this.$store.commit('userRoles/logout')
      this.$router.push({ path: '/auth/login' })
    },
    async getUser () {
      const [, response, data] = await handlePromise(UserService.getUser(this.userData.user.id))
      if (response.ok) {
        this.user = data
      }
    },
    toggleDialog () {
      this.dialogVisible = true
    },
    closeModal () {
      this.dialogVisible = false
    },
    handlePermission () {
      if (this.userModule.module === 1) {
        this.module = true
      } else {
        this.module = false
      }
      let rolesArray = Object.values(this.userRole)
      rolesArray.map(role => {
        if (role === 'ROLE_OPERATOR') {
          this.hasPermission = false
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_ADMIN') {
              this.hasPermission = true
            }
          })
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_SUPER_ADMIN') {
              this.hasPermission = true
            }
          })
          rolesArray.map(role2 => {
            if (role2 === 'ROLE_MANAGER') {
              this.hasPermission = true
            }
          })
        }
      })
    }
  },
  watch: {
    '$i18n.locale': {
      handler (val) {
        const assignName = item => {
          this.$set(item, 'name', item.langs[this.$i18n.locale])
          if (item.children && Object.keys(item.children).length) {
            for (const key in item.children) {
              assignName(item.children[key])
            }
          }
        }
        for (const key in this.energyNavigation) {
          assignName(this.energyNavigation[key])
        }
        for (const key in this.energyOperatorNavigation) {
          assignName(this.energyOperatorNavigation[key])
        }
        for (const key in this.waterNavigation) {
          assignName(this.waterNavigation[key])
        }
        for (const key in this.waterOperatorNavigation) {
          assignName(this.waterOperatorNavigation[key])
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .app-wrap {
    display: flex;
  }

  /deep/ .ods-main-header__logo .logo_text h2 {
    font-size: 16px;
    font-weight: 400;
  }
</style>
