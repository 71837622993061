<template>
  <DialogFormVue
    :width="width"
    :visible="dialogVisible"
    @submit="handleSubmit"
    :title="toEdit ? 'editChild' : 'newChild'"
    :loading="loading"
    @cancel="$emit('cancel')"
  >
    <ods-form class="mt-1" :model="form" ref="form" v-loading="loading">
      <ods-row :gutter="20">
        <ods-col :md="12">
          <ods-form-item
            :label="$t('networkElement')"
            prop="networkElement"
            :rules="[errors.required]"
          >
            <ods-select
              v-model="form.networkElement"
              remote
              :disabled="toEdit ? true : false"
            >
              <ods-option
                v-for="item in networkElements"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </ods-option>
            </ods-select>
          </ods-form-item>
        </ods-col>
        <ods-col :md="12">
          <ods-form-item
            :label="$t('originSystemId')"
            prop="originSystemId"
            :rules="[errors.required]"
          >
            <ods-input
              v-model="form.originSystemId"
              :disabled="toEdit ? true : false"
            ></ods-input>
          </ods-form-item>
        </ods-col>
      </ods-row>

      <ods-row :gutter="20">
        <ods-col :md="12">
          <ods-form-item
            :label="$t('name')"
            prop="name"
            :rules="[errors.required]"
          >
            <ods-input v-model="form.name"></ods-input>
          </ods-form-item>
        </ods-col>
        <ods-col :md="12">
          <ods-form-item
            :label="$t('technicalLosses')"
            prop="technicalLosses"
            :rules="[errors.required]"
          >
            <ods-input v-model="form.technicalLosses"></ods-input>
          </ods-form-item>
        </ods-col>
      </ods-row>

      <ods-row :gutter="20">
        <ods-col :md="12">
          <ods-form-item
            :label="$t('latitude')"
            prop="latitude"
            :rules="[errors.required]"
          >
            <ods-input v-model="form.latitude"></ods-input>
          </ods-form-item>
        </ods-col>
        <ods-col :md="12">
          <ods-form-item
            :label="$t('longitude')"
            prop="longitude"
            :rules="[errors.required]"
          >
            <ods-input v-model="form.longitude"></ods-input>
          </ods-form-item>
        </ods-col>
      </ods-row>

      <ods-row :gutter="20">
        <ods-col :md="12">
          <ods-form-item :label="$t('voltageType')" prop="voltageType">
            <ods-select v-model="form.tensionType" clearable>
              <ods-option
                v-for="item in voltages"
                :key="item.id"
                :label="item.description"
                :value="item.value"
              >
              </ods-option>
            </ods-select>
          </ods-form-item>
        </ods-col>

        <ods-col :md="12">
          <ods-form-item
            :label="$t('transportationDivision')"
            prop="transportDivision"
          >
            <ods-select v-model="form.energyTransportDivision" clearable>
              <ods-option
                v-for="item in transportes"
                :key="item.id"
                :label="item.description"
                :value="item.value"
              >
              </ods-option>
            </ods-select>
          </ods-form-item>
        </ods-col>
      </ods-row>

      <ods-row :gutter="20">
        <ods-col :md="12">
          <ods-form-item :label="$t('region')" prop="region">
            <ods-select v-model="form.region" clearable>
              <ods-option
                v-for="item in regiones"
                :key="item.id"
                :label="item.description"
                :value="item.value"
              >
              </ods-option>
            </ods-select>
          </ods-form-item>
        </ods-col>

        <ods-col :md="12">
          <ods-form-item label="ASC" prop="asc">
            <ods-select v-model="form.commercialOffice" clearable>
              <ods-option
                v-for="item in ascs"
                :key="item.id"
                :label="item.description"
                :value="item.value"
              >
              </ods-option>
            </ods-select>
          </ods-form-item>
        </ods-col>
      </ods-row>

      <ods-row :gutter="20">
        <ods-col :md="12">
          <ods-form-item :label="$t('generationType')" prop="generationType">
            <ods-select v-model="form.generationType" clearable>
              <ods-option
                v-for="item in generations"
                :key="item.id"
                :label="item.description"
                :value="item.value"
              >
              </ods-option>
            </ods-select>
          </ods-form-item>
        </ods-col>

        <ods-col :md="12">
          <ods-form-item :label="$t('clientType')" prop="clientType">
            <ods-select v-model="form.customerType" clearable>
              <ods-option
                v-for="item in clients"
                :key="item.id"
                :label="item.description"
                :value="item.value"
              >
              </ods-option>
            </ods-select>
          </ods-form-item>
        </ods-col>
      </ods-row>

      <ods-row :gutter="20">
        <ods-col :md="12">
          <ods-form-item :label="$t('voltage')" prop="voltage">
            <ods-input v-model="form.voltage"></ods-input>
          </ods-form-item>
        </ods-col>
        <ods-col :md="12">
          <ods-form-item :label="$t('voltageOut')" prop="voltageOut">
            <ods-input v-model="form.voltageOut"></ods-input>
          </ods-form-item>
        </ods-col>
      </ods-row>

      <ods-row :gutter="20">
        <ods-col :md="12">
          <ods-form-item :label="$t('length')" prop="length">
            <ods-input v-model="form.length"></ods-input>
          </ods-form-item>
        </ods-col>
        <ods-col :md="12">
          <ods-form-item :label="$t('district')" prop="district">
            <ods-input v-model="form.district"></ods-input>
          </ods-form-item>
        </ods-col>
      </ods-row>

      <ods-row :gutter="20">
        <ods-col :md="12">
          <ods-form-item :label="$t('location')" prop="location">
            <ods-input v-model="form.location"></ods-input>
          </ods-form-item>
        </ods-col>
        <ods-col :md="12">
          <ods-form-item :label="$t('address')" prop="address">
            <ods-input v-model="form.address"></ods-input>
          </ods-form-item>
        </ods-col>
      </ods-row>

      <ods-row :gutter="20">
        <ods-col :md="12">
          <ods-form-item :label="$t('serialNumber')" prop="serialNumber">
            <ods-input v-model="form.serialNumber"></ods-input>
          </ods-form-item>
        </ods-col>
        <ods-col :md="12">
          <ods-form-item :label="$t('manufacturer')" prop="manufacturer">
            <ods-input v-model="form.manufacturer"></ods-input>
          </ods-form-item>
        </ods-col>
      </ods-row>

      <ods-row :gutter="20">
        <ods-col :md="12">
          <ods-form-item :label="$t('cableMaterial')" prop="cableMaterial">
            <ods-input v-model="form.cableMaterial"></ods-input>
          </ods-form-item>
        </ods-col>
        <ods-col :md="12">
          <ods-form-item :label="$t('cableSection')" prop="cableSection">
            <ods-input v-model="form.cableSection"></ods-input>
          </ods-form-item>
        </ods-col>
      </ods-row>
    </ods-form>
  </DialogFormVue>
</template>

<script>
import errors from "@/config/InputErrors";
import _ from "lodash";
import handlePromise from "@/utils/promise";
import moment from "moment";
import NetworkNodeService from "@/services/NetworkNode";
import NetworkUnitService from "@/services/NetworkUnit";
import Vue from "vue";
import DialogFormVue from "../../../custom-components/DialogForm.vue";
import { thisTypeAnnotation } from "babel-types";

export default {
  name: "EditDataEntryDialog",
  props: {
    dialogVisible: Boolean,
    width: String,
    toEdit: {
      type: Array,
      default: null,
    },
    voltages: {
      type: Array,
      default: [],
    },
    transportes: {
      type: Array,
      default: [],
    },
    regiones: {
      type: Array,
      default: [],
    },
    ascs: {
      type: Array,
      default: [],
    },
    generations: {
      type: Array,
      default: [],
    },
    clients: {
      type: Array,
      default: [],
    },
    networkElements: {
      type: Array,
      default: [],
    },
    toEdit: {
      type: Object,
      default: null,
    },
  },
  components: {
    DialogFormVue,
  },
  data() {
    return {
      loading: false,
      form: {},
      errors,
    };
  },
  methods: {
    handleSubmit() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          const toSend = {
            originSystemId: this.form.originSystemId,
            name: this.form.name,
            technicalLosses: this.form.technicalLosses,
            networkElement: this.form.networkElement,
            voltage: this.form.voltage,
            length: this.form.length,
            region: this.form.region,
            district: this.form.district,
            location: this.form.location,
            address: this.form.address,
            serialNumber: this.form.serialNumber,
            manufacturer: this.form.manufacturer,
            cableMaterial: this.form.cableMaterial,
            cableSection: this.form.cableSection,
            voltageOut: this.form.voltageOut,
            tensionType: this.form.tensionType,
            energyTransportDivision: this.form.energyTransportDivision,
            commercialOffice: this.form.commercialOffice,
            customerType: this.form.customerType,
            generationType: this.form.generationType,
            geometry: {
              type: "Point",
              coordinates: [this.form.latitude, this.form.longitude],
            },
          };
          this.toggleLoading();
          const [error, response] = await handlePromise(
            this.toEdit
              ? NetworkUnitService.updateNetworkUnit(this.toEdit.id, toSend)
              : NetworkUnitService.createNetworkUnit(toSend)
          );
          if (this.loading) this.toggleLoading();
          if (!response.ok) {
            return this.$store.commit("settings/toggleAlert", this.$t(error));
          }
          this.$store.commit(
            "settings/toggleSuccessAlert",
            this.$t("dataEntrySaved")
          );
          this.$emit("submit");
        }
      });
    },

    toggleLoading() {
      this.loading = !this.loading;
    },
  },
  watch: {
    dialogVisible: function () {
      if (this.toEdit) {
        console.log(this.toEdit, "first");
        this.form = {
          networkElement: this.toEdit.network_element
            ? this.toEdit.network_element.id
            : null,
          originSystemId: this.toEdit.origin_system_id,

          name: this.toEdit.name,
          technicalLosses: this.toEdit.technical_losses,
          voltage: this.toEdit.voltage,
          voltageOut: this.toEdit.voltage_out,
          length: this.toEdit.length,
          district: this.toEdit.district,
          location: this.toEdit.location,
          address: this.toEdit.address,
          serialNumber: this.toEdit.serial_number,
          manufacturer: this.toEdit.manufacturer,
          cableMaterial: this.toEdit.cable_material,
          cableSection: this.toEdit.cable_section,

          latitude: this.toEdit.geometry ? this.toEdit.geometry.coordinates[0] : null,
          longitude: this.toEdit.geometry ? this.toEdit.geometry.coordinates[1] : null,

          tensionType: this.toEdit.tension_type,
          energyTransportDivision: this.energy_transport_division,
          region: this.toEdit.region,
          commercialOffice: this.toEdit.commercial_office,
          generationType: this.toEdit.generation_type,
          customerType: this.toEdit.customer_type,
        };
      } else {
        this.form = {};
      }
    },
  },
};
</script>
