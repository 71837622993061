<template>
  <ods-module :class="'tree__module mt-1 mb-1'" :header="$t('networkTree')">
      <v-chart ref="vchart" style="width: 100%" @click="(data) => $emit('node-click', data)" :options="chart" autoresize="autoresize" />
  </ods-module>
</template>

<script>
import ECharts from 'vue-echarts'
import 'echarts/lib/chart/graph'
import lesMiserables from '../../views/les-miserables.json'

export default {
  name: 'NetworkGraph',
  components: {
    'v-chart': ECharts
  },
  props: {
    chartData: {}
  },
  data () {
    return {
      chart: {},
      filterText: '',
      testData: lesMiserables
    }
  },
  mounted () {
    this.buildGraph()
  },
  methods: {
    buildGraph () {
      this.chart = {
        onClick: this.handleChartClick,
        tooltip: {},
        legend: [
          {
            data: this.chartData.categories.map(function (a) {
              return a.name
            })
          }
        ],
        series: [
          {
            name: 'Node:',
            type: 'graph',
            layout: 'none',
            width: '100%',
            data: this.chartData.nodes,
            links: this.chartData.links,
            categories: this.chartData.categories,
            roam: true,
            label: {
              show: true,
              position: 'right',
              formatter: '{b}'
            },
            labelLayout: {
              hideOverlap: true
            },
            scaleLimit: {
              min: 0,
              max: 4
            },
            lineStyle: {
              color: 'source',
              curveness: 0.08
            },
            emphasis: {
              focus: 'adjacency',
              lineStyle: {
                width: 10
              }
            }
          }
        ]
      }
    }
  },
  watch: {
    'chartData': function (val) {
      this.buildGraph()
    }
  }
}
</script>
